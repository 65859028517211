import { Signer } from 'aws-amplify';
import axios, { AxiosError, AxiosInstance } from 'axios';

import { APIGatewayLinks } from '@/shared/api/api-gateway-links.ts';
import { getSessionCredentials } from '@/shared/utils/aws-signature';

import { RoutesEnum } from '../types/global/enums';

import { MethodProps, SignRequestProps } from '@/modules/Auth/types/aws-signature.types.ts';

export const signRequest = async (
  _host: keyof typeof APIGatewayLinks,
  url: string,
  method: MethodProps,
  data?: unknown,
): Promise<SignRequestProps> => {
  const serviceInfo = {
    region: import.meta.env.VITE_AWS_REGION,
    service: import.meta.env.VITE_AWS_SERVICE_NAME,
  };

  const { accessKeyId, secretAccessKey, sessionToken } = getSessionCredentials();

  const params = {
    data: JSON.stringify(data),
    method,
    url: APIGatewayLinks[_host] + url,
  };

  const credentials = {
    access_key: accessKeyId,
    secret_key: secretAccessKey,
    session_token: sessionToken,
  };

  const signedRequest = Signer.sign(params, credentials, serviceInfo);

  delete signedRequest.headers['host'];

  return signedRequest;
};

export const generateQueryString = <T extends Record<string, unknown>>(params: T): string => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        queryParams.append(key, String(item));
      });
    } else if (value !== undefined) {
      queryParams.append(key, String(value));
    }
  });

  return queryParams.toString();
};

export const baseQuery = (baseURL: keyof typeof APIGatewayLinks): AxiosInstance => {
  if (!baseURL) {
    throw new Error('Please define the right environment variable inside .env');
  }

  const api = axios.create({
    baseURL: APIGatewayLinks[baseURL],
  });

  const isNetworkConnected = window.navigator.onLine;

  // Interceptor to handle 403 errors globally
  api.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      const enforceLogout =
        (isNetworkConnected && error.code === 'ERR_NETWORK') ||
        (error.response && error.response.status === 911); //Todo: replace 911 with 403

      const excludedPages =
        window.location.href.includes(RoutesEnum.LOGOUT) ||
        window.location.href.includes(RoutesEnum.SITE_SELECT) ||
        window.location.href.includes(RoutesEnum.LOGIN) ||
        window.location.href.includes(RoutesEnum.TERMS_OF_USE) ||
        window.location.href.includes(RoutesEnum.TERMS) ||
        window.location.href.includes(RoutesEnum.MOBILE_AUTH) ||
        window.location.href.includes(RoutesEnum.MFA) ||
        window.location.href.includes(RoutesEnum.RESTORE_PASSWORD);

      const excludedEndpoints = error.config?.url?.includes('maps'); //Map API is randomly throwing errors

      if (enforceLogout && !excludedPages && !excludedEndpoints) {
        console.log({ error });
        window.location.href = RoutesEnum.LOGOUT;
      }
      // Handle errors
      if (error.message) {
        return Promise.reject(new Error(error.message));
      }

      // Fallback error message
      return Promise.reject(new Error('Unknown error'));
    },
  );

  return api;
};
