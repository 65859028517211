import { FC } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router';

import { PrismicProvider } from '@prismicio/react';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';

import { I18nProvider } from '@/i18n';
import { AppRoutes } from '@/routes';
import { Popup } from '@/shared/components/interactions/Popup';
import { CategoryProvider } from '@/shared/context/category-ctree.tsx';
import { useLanguageFont } from '@/shared/hooks/global/useLanguageFont.ts';
import { useFullscreenLoader } from '@/shared/hooks/ui/useFullscreenLoader.ts';
import { client } from '@/shared/prismic-client/prismic.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { toastOptions } from '@/shared/utils/toaster.ts';

const environmentID = import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID;

export const App: FC = () => {
  const currentLanguage = useGlobalStore(state => state.currentLanguage);
  const popups = useGlobalStore(state => state.popups);

  const renderPopupsList = () => {
    return popups.map((popup, index) => <Popup key={popup.id} {...{ index, popup }} />);
  };

  useLanguageFont();
  useFullscreenLoader();

  return (
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{
        environmentID,
      }}
    >
      <PrismicProvider client={client}>
        <I18nProvider locale={currentLanguage}>
          <HelmetProvider>
            <BrowserRouter>
              <CategoryProvider>
                <AppRoutes />

                {renderPopupsList()}
              </CategoryProvider>

              <Toaster position="top-center" reverseOrder={true} toastOptions={toastOptions} />
            </BrowserRouter>
          </HelmetProvider>
        </I18nProvider>
      </PrismicProvider>
    </FlagsmithProvider>
  );
};
