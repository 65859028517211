import { queryOptions, useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';

import { useFetchUserData } from '@/modules/Auth/hooks/useFetchUserData.ts';
import { AssetsAPINamespace } from '@/shared/api/assets/assets.requests.ts';
import { useCategoryContext } from '@/shared/context/category-ctree.tsx';
import { QUERY_SCHEMA } from '@/shared/schemas/query-schemas.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { QUERY_KEY, REQUESTS_OPTIONS } from '@/shared/utils/constants.ts';

import { AssetsFullDetailsParams, CombineResultsProps } from '@/shared/api/assets/assets.types.ts';
import { AssetBody, AssetsResponse } from '@/shared/types/assets/assets.types.ts';

export namespace useAssetHooks {
  export const getAllEquipments = () => {
    const { getCategoriesByType } = useCategoryContext();

    const site = useGlobalStore(store => store.site);
    const equipmentVisibility = useGlobalStore(state => state.equipmentVisibility);

    const { user } = useFetchUserData();

    const assetsCategories = getCategoriesByType('Equipment');
    const categories: string[] = assetsCategories.map(item => item.categoryTreeResName);

    return useQueries({
      combine: (results: UseQueryResult<AssetsResponse, Error>[]): CombineResultsProps => {
        return {
          data: results
            .filter(result => result?.data?.results)
            .flatMap(result => {
              return result?.data?.results.map(equipment => ({
                ...equipment,
                visible: equipmentVisibility,
              }));
            }) as AssetBody[],
          isError: results.some(result => result.isError),
          isFetching: results.some(result => result.isFetching),
          isLoading: results.length > 0 ? results.some(result => result.isLoading) : true,
          isPending: results.some(result => result.isPending),
          isSuccess: results.every(result => result.isSuccess),
          refetch: () => results.forEach(result => result.refetch()),
        };
      },

      queries: categories.map(category => ({
        enabled: !!user?.accountResourceName && !!site,

        queryFn: async () => {
          const params: AssetsFullDetailsParams = {
            includeTags: true,
            limit: REQUESTS_OPTIONS.LIMIT_POINTS,
            statuses: ['active', 'inactive'],
            treeResName: category,
          };

          return await AssetsAPINamespace.getAllWithDetails({
            accountResName: user?.accountResourceName as string,
            params,
            siteResName: site,
          });
        },

        queryKey: [QUERY_KEY.EQUIPMENTS_GET, category, user, site],
      })),
    });
  };

  export const getAllWorkers = () => {
    const { getCategoriesByType, getCategoryByType } = useCategoryContext();

    const site = useGlobalStore(store => store.site);
    const workersVisibility = useGlobalStore(state => state.workerVisibility);

    const { user } = useFetchUserData();

    const assetsCategories = getCategoriesByType('Worker');
    const currentCategory = getCategoryByType('Worker')?.categoryTreeResName;

    const workerQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!site && assetsCategories.length > 0,

      queryFn: async () => {
        const params: AssetsFullDetailsParams = {
          includeTags: true,
          limit: REQUESTS_OPTIONS.LIMIT_POINTS,
          statuses: ['active', 'inactive'],
          treeResName: currentCategory,
        };

        return await AssetsAPINamespace.getAllWithDetails({
          accountResName: user?.accountResourceName as string,
          params,
          siteResName: site,
        });
      },

      queryKey: [QUERY_KEY.WORKERS, user, site, currentCategory],

      select: data => {
        return data.results.map(worker => ({
          ...worker,
          visible: workersVisibility,
        }));
      },
    });

    return useQuery(workerQueryOptions);
  };

  export const getCategoryTrees = () => {
    const site = useGlobalStore(state => state.site);

    const { user } = useFetchUserData();

    const assetsCategoriesQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!site,

      queryFn: async () => {
        return await AssetsAPINamespace.getAllCategoryTress(
          user?.accountResourceName as string,
          site,
        );
      },

      queryKey: [QUERY_KEY.ASSETS_CATEGORIES, user, site],

      select: data => {
        return data.map(item => {
          const modifiedItemPaths = item.categoryPathList.map(pathItem => {
            return {
              ...pathItem,
              extended: false,
            };
          });

          return {
            ...item,
            categoryPathList: modifiedItemPaths,
            extended: true,
          };
        });
      },

      staleTime: QUERY_SCHEMA.STALE_TIME.DAY,
    });

    return useQuery(assetsCategoriesQueryOptions);
  };
}
